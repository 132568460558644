import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3872c6f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pagination"
}
const _hoisted_2 = {
  key: 0,
  class: "pagination__pagination"
}
const _hoisted_3 = { class: "pagination__container" }
const _hoisted_4 = { class: "pagination__counter" }
const _hoisted_5 = {
  key: 2,
  class: "pagination__pagination-item dots"
}
const _hoisted_6 = {
  key: 7,
  class: "pagination__pagination-item dots"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowPagination = _resolveComponent("ArrowPagination")!

  return (_ctx.propertyMeta)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.propertyMeta.last_page != undefined && _ctx.propertyMeta.last_page > 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                ((_ctx.propertyMeta.current_page != 1))
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handlePage(_ctx.page - 1))),
                      class: "nav prev"
                    }, [
                      _createVNode(_component_ArrowPagination)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handlePage(1))),
                    class: _normalizeClass(['pagination__pagination-item', _ctx.propertyMeta.current_page == 1 && 'active'])
                  }, "1", 2),
                  (_ctx.propertyMeta.last_page >= 2)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handlePage(2))),
                        class: _normalizeClass(['pagination__pagination-item', _ctx.propertyMeta.current_page == 2 && 'active'])
                      }, "2", 2))
                    : _createCommentVNode("", true),
                  (_ctx.propertyMeta.last_page >= 3)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handlePage(3))),
                        class: _normalizeClass(['pagination__pagination-item', _ctx.propertyMeta.current_page == 3 && 'active'])
                      }, "3", 2))
                    : _createCommentVNode("", true),
                  (_ctx.propertyMeta.current_page > 5 && _ctx.propertyMeta.last_page > 6)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, "..."))
                    : _createCommentVNode("", true),
                  (_ctx.propertyMeta.current_page == _ctx.propertyMeta.last_page && _ctx.propertyMeta.last_page > 5)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 3,
                        class: _normalizeClass(['pagination__pagination-item', _ctx.propertyMeta.current_page - 2 == _ctx.page  && 'active']),
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handlePage(_ctx.propertyMeta.current_page - 2)))
                      }, _toDisplayString(_ctx.propertyMeta.current_page - 2), 3))
                    : _createCommentVNode("", true),
                  (_ctx.propertyMeta.current_page > 4)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 4,
                        class: _normalizeClass(['pagination__pagination-item', _ctx.propertyMeta.current_page - 1 == _ctx.page  && 'active']),
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handlePage(_ctx.propertyMeta.current_page - 1)))
                      }, _toDisplayString(_ctx.propertyMeta.current_page - 1), 3))
                    : _createCommentVNode("", true),
                  (_ctx.propertyMeta.current_page > 3)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 5,
                        class: _normalizeClass(['pagination__pagination-item', _ctx.propertyMeta.current_page == _ctx.page  && 'active']),
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handlePage(_ctx.propertyMeta.current_page)))
                      }, _toDisplayString(_ctx.propertyMeta.current_page), 3))
                    : _createCommentVNode("", true),
                  (_ctx.propertyMeta.current_page > 2 && _ctx.propertyMeta.last_page - _ctx.propertyMeta.current_page > 2)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 6,
                        class: _normalizeClass(['pagination__pagination-item', _ctx.propertyMeta.current_page + 1 == _ctx.page && 'active']),
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.handlePage(_ctx.propertyMeta.current_page + 1)))
                      }, _toDisplayString(_ctx.propertyMeta.current_page + 1), 3))
                    : _createCommentVNode("", true),
                  (_ctx.propertyMeta.last_page - _ctx.propertyMeta.current_page > 4 && _ctx.propertyMeta.last_page > 6)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, "..."))
                    : _createCommentVNode("", true),
                  (_ctx.propertyMeta.last_page - _ctx.propertyMeta.current_page > 3 && _ctx.propertyMeta.last_page > 5)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 8,
                        class: _normalizeClass(['pagination__pagination-item', _ctx.propertyMeta.last_page - 2 == _ctx.page  && 'active']),
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handlePage(_ctx.propertyMeta.last_page - 2)))
                      }, _toDisplayString(_ctx.propertyMeta.last_page - 2), 3))
                    : _createCommentVNode("", true),
                  (_ctx.propertyMeta.last_page - _ctx.propertyMeta.current_page > 1 && _ctx.propertyMeta.last_page > 4)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 9,
                        class: _normalizeClass(['pagination__pagination-item', _ctx.propertyMeta.last_page - 1 == _ctx.page  && 'active']),
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.handlePage(_ctx.propertyMeta.last_page - 1)))
                      }, _toDisplayString(_ctx.propertyMeta.last_page - 1), 3))
                    : _createCommentVNode("", true),
                  (_ctx.propertyMeta.current_page != _ctx.propertyMeta.last_page && _ctx.propertyMeta.last_page > 3)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 10,
                        class: "pagination__pagination-item",
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.handlePage(_ctx.propertyMeta.last_page)))
                      }, _toDisplayString(_ctx.propertyMeta.last_page), 1))
                    : _createCommentVNode("", true)
                ]),
                ((_ctx.propertyMeta.current_page != _ctx.propertyMeta.last_page))
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.handlePage(_ctx.page + 1))),
                      class: "nav next"
                    }, [
                      _createVNode(_component_ArrowPagination)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}